import React, { useState, useEffect } from 'react'
import logo from './assets/img/logo.png'
import './assets/css/style.css'
import Slideshow from './components/Slideshow'
import Info from './components/Info'
import Viviendas from './components/Viviendas'
import Fotos from './components/Fotos'

function App() {
  const [blockActive, setActiveBlock] = useState('home')

  const openBlock = (str) => {
    setActiveBlock(str)
  }

  const objectFitIE = () => {
    if('objectFit' in document.documentElement.style === false) {   
      var container = document.getElementsByClassName('js-box');
      
      for(var i = 0; i < container.length; i++) {
        var imageSource = container[i].querySelector('img').src;
        container[i].querySelector('img').style.display = 'none';
        container[i].style.backgroundSize = 'cover';
        container[i].style.backgroundImage = 'url(' + imageSource + ')';
        container[i].style.backgroundPosition = 'center center';
      }
    }
    else {
      console.log('your browser supports objectFit')
    }
  }

  useEffect(() => {
    objectFitIE()
  }, [])

  return (
    <>
      <span className={ blockActive === 'home' ? 'backOp on' : 'backOp' }></span>
      <header className={ blockActive === 'home' ? 'header noBorder' : 'header' }>
        <div className="container">
          <div className="flex">
            <div className="logo">
              <img src={logo} alt="Residencial Calvià Pueblo" />
            </div>

            <nav className="mainNav">
              <ul className="list">
                <li><button className="btnNav" onClick={() => openBlock('home')}>Inicio</button></li>
                <li><span className="sep">|</span></li>
                <li><button className="btnNav" onClick={() => openBlock('info')}>Información</button></li>
                <li><span className="sep">|</span></li>
                <li><button className="btnNav" onClick={() => openBlock('fotos')}>Fotos</button></li>
                <li><span className="sep">|</span></li>
                <li><button className="btnNav" onClick={() => openBlock('planos')}>Planos</button></li>
                <li><span className="sep">|</span></li>
                <li><a className="btnNav" href="https://www.klapty.com/tour/hxM08t9bg2" target="_blank">Visita Virtual</a></li>
                <li><span className="sep">|</span></li>
                <li><a className="btnNav" href="https://goo.gl/maps/kmW671cQWuTmF3Jc9" target="_blank">Ubicación</a></li>
              </ul>
            </nav>
          </div>
        </div>
      </header>

      <main>
        <section className={ blockActive === 'home' ? 'block home open' : 'block home' }>
          <Slideshow />
        </section>

        <section className={ blockActive === 'info' ? 'block info open' : 'block info' }>
          <div className="container">
            <Info />
          </div>
        </section>

        <section className={ blockActive === 'fotos' ? 'block photos open' : 'block photos' }>
          <div className="container">
            <Fotos />
          </div>
        </section>

        <section className={ blockActive === 'planos' ? 'block households open' : 'block households' }>
          <div className="container">
            <Viviendas />
          </div>
        </section>
      </main>
    </>
  )
}

export default App
