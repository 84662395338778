import React from 'react'

const Info = () => {

  return <div className="content">
    <div className="left">
      <div className="scrollView">
        <p className="desc">Este singular Proyecto de Obra nueva, consiste en un íntimo complejo de 8 chalets ubicados en el idílico Pueblo de Calvià.<br /><br />
El pueblo de Calvià presume de su antigua arquitectura y de sus históricas calles que escapan de las masificaciones que se dan en las zonas costeras.<br /><br />
Los tonos terrosos mezclados con la arquitectura mallorquina envuelven las magníficas
fachadas de estos chalets, los amplios espacios con terrazas y jardines al aire libre y su interiorismo atento, definen estos hogares diseñados acorde a su entorno natural hasta el punto de mezclarse por completo con los aires del Pueblo de Calvià.<br /><br />
Los chalets se distribuyen, en 3 fabulosos dormitorios dobles, 3 baños, la cocina y el comedor se encuentran dentro de un plano abierto que deja entrar la luz y el aire fresco, disponen de previsión de chimenea y jacuzzi de amplias terrazas con jardines y aparcamientos privados, todo ello hará de su hogar el lugar más acogedor del mundo.</p>
        <p className="desc">This unique New Construction Project consists of an intimate complex of 8 villas located in the idyllic town of Calvià. The town of Calvià boasts its old architecture and its historic streets that escape the crowds that occur in the coastal areas.<br /><br /> The earthy tones mixed with the Mallorcan architecture surround the magnificent facades of these villas, the wide spaces with terraces and outdoor gardens and their attentive interior design, define these homes designed according to their natural environment to the point of completely blending with the air from the Town of Calvià. The chalets are distributed in 3 fabulous double bedrooms, 3 bathrooms, the kitchen and the dining room are located within an open plan that lets in light and fresh air, they have a fireplace and Jacuzzi, large terraces with gardens and parking. private, all this will make your home the most welcoming place in the world.</p>
      </div>
    </div>

    <div className="right">
      <div className="modContact">
        <p>CRISTINA CAMPOMAR<br />
        Jefe de ventas<br />
          <a href="mailto:ventas@residencialcalviapueblo.com">ventas@residencialcalviapueblo.com</a>
T. (+34) 656 694 289</p>
      </div>
      <div className="modContact">
        <p>PACO MUÑOZ<br />
        Departamento Comercial<br />
          <a href="mailto:comercial@residencialcalviapueblo.com">comercial@residencialcalviapueblo.com</a>
T. (+34) 630 688 253</p>
      </div>
    </div>
  </div>
}

export default Info;