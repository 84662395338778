import React from 'react'
import Carousel from 'nuka-carousel'

import slideHome01 from '../assets/images/home01.webp'
import slideHome01b from '../assets/img/home01.jpg'
import slideHome02 from '../assets/images/home02.webp'
import slideHome02b from '../assets/img/home02.jpg'
import slideHome03 from '../assets/images/home03.webp'
import slideHome03b from '../assets/img/home03.jpg'
import slideHome04 from '../assets/images/home04.webp'
import slideHome04b from '../assets/img/home04.jpg'
import slideHome05 from '../assets/images/home05.webp'
import slideHome05b from '../assets/img/home05.jpg'
import slideHome06 from '../assets/images/home06.webp'
import slideHome06b from '../assets/img/home06.jpg'
import slideHome07 from '../assets/images/home07.webp'
import slideHome07b from '../assets/img/home07.jpg'
import slideHome08 from '../assets/images/home08.webp'
import slideHome08b from '../assets/img/home08.jpg'
import slideHome09 from '../assets/images/home09.webp'
import slideHome09b from '../assets/img/home09.jpg'
import slideHome10 from '../assets/images/home10.webp'
import slideHome10b from '../assets/img/home10.jpg'

const Slideshow = () => {

  return <div className="content">
    <div className="slideshow">
      <Carousel
        autoplay={true}
        wrapAround={true}
        swiping={true}
        speed={6000}
        transitionMode="fade"
        defaultControlsConfig={{
          nextButtonClassName: 'next',
          prevButtonClassName: 'prev',
          pagingDotsClassName: 'dot',
          pagingDotsStyle: {
            position: 'relative',
            bottom: '40px'
          }
        }}>
        <div className="js-box">
          <picture>
            <source srcSet={slideHome01} type="image/webp" />
            <source srcSet={slideHome01b} type="image/jpeg" />
            <img src={slideHome01b} alt="" />
          </picture>
        </div>
        <div className="js-box">
          <picture>
            <source srcSet={slideHome02} type="image/webp" />
            <source srcSet={slideHome02b} type="image/jpeg" />
            <img src={slideHome02b} alt="" />
          </picture>
        </div>
        <div className="js-box">
          <picture>
            <source srcSet={slideHome03} type="image/webp" />
            <source srcSet={slideHome03b} type="image/jpeg" />
            <img src={slideHome03b} alt="" />
          </picture>
        </div>
        <div className="js-box">
          <picture>
            <source srcSet={slideHome04} type="image/webp" />
            <source srcSet={slideHome04b} type="image/jpeg" />
            <img src={slideHome04b} alt="" />
          </picture>
        </div>
        <div className="js-box">
          <picture>
            <source srcSet={slideHome05} type="image/webp" />
            <source srcSet={slideHome05b} type="image/jpeg" />
            <img src={slideHome05b} alt="" />
          </picture>
        </div>
        <div className="js-box">
          <picture>
            <source srcSet={slideHome06} type="image/webp" />
            <source srcSet={slideHome06b} type="image/jpeg" />
            <img src={slideHome06b} alt="" />
          </picture>
        </div>
        <div className="js-box">
          <picture>
            <source srcSet={slideHome07} type="image/webp" />
            <source srcSet={slideHome07b} type="image/jpeg" />
            <img src={slideHome07b} alt="" />
          </picture>
        </div>
        <div className="js-box">
          <picture>
            <source srcSet={slideHome08} type="image/webp" />
            <source srcSet={slideHome08b} type="image/jpeg" />
            <img src={slideHome08b} alt="" />
          </picture>
        </div>
        <div className="js-box">
          <picture>
            <source srcSet={slideHome09} type="image/webp" />
            <source srcSet={slideHome09b} type="image/jpeg" />
            <img src={slideHome09b} alt="" />
          </picture>
        </div>
        <div className="js-box">
          <picture>
            <source srcSet={slideHome10} type="image/webp" />
            <source srcSet={slideHome10b} type="image/jpeg" />
            <img src={slideHome10b} alt="" />
          </picture>
        </div>
      </Carousel>
    </div>

    <div className="slogan">
      <p>PROMOCIÓN DE OBRA NUEVA ÍNTIMO COMPLEJO DE 8 CHALETS UBICADOS EN EL IDÍLICO PUEBLO DE CALVIÀ.</p>
    </div>
  </div>
}

export default Slideshow;