import React from 'react'
import foto01 from '../assets/img/home01.jpg'
import foto02 from '../assets/img/home02.jpg'
import foto03 from '../assets/img/home03.jpg'
import foto04 from '../assets/img/home04.jpg'
import foto05 from '../assets/img/home05.jpg'
import foto06 from '../assets/img/home06.jpg'
import foto07 from '../assets/img/home07.jpg'
import foto08 from '../assets/img/home08.jpg'
import foto09 from '../assets/img/home09.jpg'
import foto10 from '../assets/img/home10.jpg'

const Fotos = () => {

  return <div className="content">
    <div className="scrollView">
      <ul className="list">
        <li>
          <div className="js-box"><img src={foto01} alt="" /></div>
          <p className="caption">Render de distribución, imagen de referencia</p>
        </li>
        <li>
          <div className="js-box"><img src={foto02} alt="" /></div>
          <p className="caption">Render de distribución, imagen de referencia</p>
        </li>
        <li>
          <div className="js-box"><img src={foto03} alt="" /></div>
          <p className="caption">Render de distribución, imagen de referencia</p>
        </li>
        <li>
          <div className="js-box"><img src={foto04} alt="" /></div>
          <p className="caption">Render de distribución, imagen de referencia</p>
        </li>
        <li>
          <div className="js-box"><img src={foto05} alt="" /></div>
          <p className="caption">Render de distribución, imagen de referencia</p>
        </li>
        <li>
          <div className="js-box"><img src={foto06} alt="" /></div>
          <p className="caption">Render de distribución, imagen de referencia</p>
        </li>
        <li>
          <div className="js-box"><img src={foto07} alt="" /></div>
          <p className="caption">Render de distribución, imagen de referencia</p>
        </li>
        <li>
          <div className="js-box"><img src={foto08} alt="" /></div>
          <p className="caption">Render de distribución, imagen de referencia</p>
        </li>
        <li>
          <div className="js-box"><img src={foto09} alt="" /></div>
          <p className="caption">Render de distribución, imagen de referencia</p>
        </li>
        <li>
          <div className="js-box"><img src={foto10} alt="" /></div>
          <p className="caption">Render de distribución, imagen de referencia</p>
        </li>
      </ul>
    </div>
  </div>
}

export default Fotos;