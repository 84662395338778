import React from 'react'
import plano02 from '../assets/planos/PLANOS_ventas-2.pdf'
import thumb02 from '../assets/planos/B.png'
import thumbA from '../assets/planos/A.png'
import thumbC from '../assets/planos/C.png'
import thumbD from '../assets/planos/D.png'
import thumbE from '../assets/planos/E.png'
import thumbF from '../assets/planos/F.png'
import thumbG from '../assets/planos/G.png'
import thumbH from '../assets/planos/H.png'

const Viviendas = () => {

  return <div className="content">
    <div className="scrollView">
      <ul className="list">
        <li>
          <a><img src={thumb02} alt="" /></a>
          <p className='sold'><span className="desc">B</span> <span className="price">Vendido</span></p>
        </li>
        <li>
          <a><img src={thumbA} alt="" /></a>
          <p className='sold'><span className="desc">A</span> <span className="price">Vendido</span></p>
        </li>
        <li>
          <a><img src={thumbC} alt="" /></a>
          <p className='sold'><span className="desc">C</span> <span className="price">Vendido</span></p>
        </li>
        <li>
          <a><img src={thumbD} alt="" /></a>
          <p className='sold'><span className="desc">D</span> <span className="price">Vendido</span></p>
        </li>
        <li>
          <a><img src={thumbE} alt="" /></a>
          <p className='sold'><span className="desc">E</span> <span className="price">Vendido</span></p>
        </li>
        <li>
          <a><img src={thumbF} alt="" /></a>
          <p className='sold'><span className="desc">F</span> <span className="price">Vendido</span></p>
        </li>
        <li>
          <a><img src={thumbG} alt="" /></a>
          <p className='sold'><span className="desc">G</span> <span className="price">Vendido</span></p>
        </li>
        <li>
          <a><img src={thumbH} alt="" /></a>
          <p className='sold'><span className="desc">H</span> <span className="price">Vendido</span></p>
        </li>
      </ul>
    </div>
  </div>
}

export default Viviendas;